/*
 * @Author: wangqs 
 * @description 历史账单
 * @Date: 2021-07-11 16:24:29 
 * @Last Modified by: wangqs
 * @Last Modified time: 2021-07-27 22:39:00
 */
<template>
  <div class="page-box box">
    <div class="history-box box">
      <div
        class="title"
        @click="showPicker = true"
      >
        {{ nowYear }}年
        <van-icon
          style="font-weight: bold;top: 2px;"
          name="arrow-down"
        />
      </div>
      <div class="item-box">
        <van-cell-group inset>
          <van-cell
            v-for="(item, index) of list"
            :key="index"
            :title="item.month + '月还款'"
            :value="'￥' + item.amount"
            is-link
            @click="goDetail(item)"
          />
        </van-cell-group>
      </div>
    </div>

    <!-- 年份 -->
    <van-popup
      v-model="showPicker"
      position="bottom"
    >
      <van-picker
        title="选择年份"
        show-toolbar
        :columns="columns"
        @cancel="showPicker = false"
        @confirm="onConfirm"
        @change="onChange"
      />
    </van-popup>
  </div>
</template>

<script>
export default {
  name: 'Test',
  components: {
  },
  data () {
    return {
      columns: [],
      nowYear: '2021',
      showPicker: false,
      list: []
    }
  },
  computed: {
  },
  created () {
    let nowYear = new Date().getFullYear()
    this.nowYear = nowYear.toString()
    for (var i = 0; i < 5; i++) {
      this.columns.push((nowYear - i).toString())
    }
    console.log(this.columns)
    this.getList()
  },
  methods: {
    async getList () {
      let res = await this.$http.repayApi.billHistory({id: this.nowYear})
      console.log(res, 'res')
      if (res.code === 200) {
        this.list = res.msg || []
      }
    },
    goDetail (item) {
      this.$router.push('/user/repayHistoryDetail?year=' + item.annual + '&month=' + item.month)
    },
    onConfirm (e) {
      console.log(e)
      this.nowYear = e
      this.showPicker = false
      this.getList()
    },
    onChange (e) {
      console.log(e)
    }
  }
}
</script>

<style lang="scss" scoped>
.page-box {
  background: #f5f5f5;
  min-height: 100vh;
  .history-box {
    padding: 0 20px;
    .title {
      line-height: 36px;
      font-size: 14px;
      cursor: pointer;
    }
    .item-box {
      background: #fff;
      border-radius: 4px;
    }
  }
}
</style>
